<template>
  <UForm
    :schema="schema"
    :state="state"
    @submit="handleAddUrl"
    class="flex flex-col gap-2"
  >
    <UFormGroup name="attachment_url">
      <template #label>
        <UPopover mode="hover">
          <div class="flex items-center gap-1">
            <span class="">Deck url</span>
            <span class="inline-block w-5 shrink-0 -mt-1">
              <IconResolver icon-name="question" />
            </span>
          </div>
          <template #panel>
            <div class="p-2 max-w-xs">
              We support: docsend, pitch.com, hubspot and public google docs and drive links. We will use your email as username to access the deck if required.
            </div>
          </template>
        </UPopover>
      </template>
      <UInput
        v-model="state.attachment_url"
        id="attachment_url"
        name="attachment_url"
       />
    </UFormGroup>
    <UFormGroup hint="Optional" name="attachment_email">
      <template #label>
        <UPopover mode="hover">
          <div class="flex items-center gap-1">
            <span class=""> Signin email </span>
            <span class="inline-block w-5 shrink-0 -mt-1">
              <IconResolver icon-name="question" />
            </span>
          </div>
          <template #panel>
            <div class="p-2 max-w-xs">
              By default we will use your email as username to access the deck. If you want to use a different email, you can enter it here.
            </div>
          </template>
        </UPopover>
      </template>
      <UInput
        v-model="state.attachment_email"
        id="attachment_email"
        name="attachment_email"
      />
    </UFormGroup>
    <UFormGroup hint="Optional" name="attachment_password">
      <template #label>
        <UPopover mode="hover">
          <div class="flex items-center gap-1">
            <span class=""> Deck password </span>
            <span class="inline-block w-5 shrink-0 -mt-1">
              <IconResolver icon-name="question" />
            </span>
          </div>
          <template #panel>
            <div class="p-2 max-w-xs">
              Some docsend and Pitch.com decks require a password to access the
              deck. We will use your email as username to access the deck if required.
            </div>
          </template>
        </UPopover>
      </template>
      <UInput
        v-model="state.attachment_password"
        id="attachment_password"
        name="attachment_password"
        type="password"
      />
    </UFormGroup>
    <div class="flex justify-end">
      <UButton type="submit" color="gray" label="Add to queue" />
    </div>
  </UForm>
</template>

<script lang="ts" setup>
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";
import { urlSchema } from "@/utils/validations";
const emit = defineEmits(["update"]);

// Define the schema
const schema = z.object({
  attachment_url: urlSchema.or(z.literal('')),
  attachment_email: z.string().optional(),
  attachment_password: z.string().optional(),
});

// Define type from schema
type Schema = z.output<typeof schema>;

// Initialize form state
const state = ref<Schema>({
  attachment_url: "",
  attachment_email: "",
  attachment_password: "",
});

// Handle form submission
const handleAddUrl = (event: FormSubmitEvent<Schema>) => {
  if (event.data.attachment_url === "") return;
  emit("update", event.data);
  state.value.attachment_url = "";
  state.value.attachment_email = "";
  state.value.attachment_password = "";
};
</script>
